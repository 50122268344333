<template>
  <div class="Awarp">
    <dashCard
        class="carbonBox4"
        :operateWidth="operateWidth"
        :isLoading="isLoading"
        :gridOption="gridOption"
        @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('powerBox4_monitor','负载监测', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
              class="pick-type"
              v-for="(item, idx) in timetType"
              :key="idx"
              @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
                item.name
              }}</font>
          </span>
        </div>
        <a-date-picker
            v-if="searchObj.TYPE == '按天'"
            :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
        />
        <a-week-picker
            v-if="searchObj.TYPE == '按周'"
            :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
        />
        <a-month-picker
            v-if="searchObj.TYPE == '按月'"
            :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
        />
        <a-date-picker
            v-if="searchObj.TYPE == '按年'"
            :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
            v-model="searchObj.VALUE"
            @change="onChange"
            mode="year"
            :open="panelOpen"
            @openChange="openChange"
            @panelChange="panelChange"
            format="YYYY"
        />
      </template>
      <a-select
          v-model="searchObj['ITEM']"
          style="width: 220px; margin-right: 16px; margin-bottom: 5px"
          @change="handleChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :showArrow="true"
      >
        <!-- :showArrow="true" -->
        <!-- <a-icon slot="suffixIcon" type="meh" /> -->
        <!-- <img slot="suffixIcon" src="@/assets/imgs/echarts/abb_down_32.svg" alt=""> -->
        <a-select-option
            v-for="(item, key) in dbData"
            :key="key"
            :value="item.code"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
      <div class="card-content1">
        <div class="display-item">
          <div>{{commonLangHandler('powerBox4_averageTotalPower','平均总功率', getZEdata)}}</div>
          <div>{{ detailInfo.pj }}<font>kW</font></div>
        </div>
        <div class="display-item">
          <div>{{commonLangHandler('powerBox4_maxTotalPower','最大总功率', getZEdata)}}</div>
          <div>{{ detailInfo.max }}<font>kW</font></div>
        </div>
      </div>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1"/>
      </div>
    </dashCard>
    <a-modal
        v-if="!isModal"
        class="Amodal"
        v-model="option.visible"
        :closable="false"
        :footer="null"
        :keyboard="false"
        width="100%"
        :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <carbonBox4
            :option="option"
            :isModal="true"
            :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "carbonBox4",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return {w: 6, h: 6};
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.searchObj.ITEM = "";
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },

        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      gridLeft: [],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: "",
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["#3366FF"],
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
    //dashboard数据字典
    dashboardOptions() {
      return this.$store.state.dashboardOptions;
    },
    assetDic() {
      return this.dashboardOptions.assetDic;
    },
    electricDic() {
      return this.dashboardOptions.electricDic;
    },
    dbData() {
      
      return this.$store.state.dbData;
    },
  },
  methods: {
    handleChange(value) {
      this.getData();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
          .toString()
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
    },
    //每月报警预警数
    getData() {
      // let data = {
      //   "deptCode": this.currentSelectDeptInfo.CODE,
      //   "equipment":this.searchObj.ITEM
      // }

      if (this.searchObj.ITEM == "") {
        this.detailInfo.chart1 = {
          categoryData: [],
          seriesData: [
            {
              name: "",
              value: [],
            },
          ],
        };
        this.detailInfo.max = 0;
        this.detailInfo.pj = 0;
        this.initEchart();
        return;
      }
      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
        equipment: this.searchObj.ITEM,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/circuit/loadspace",
        method: "post",
        data,
      })
          .then((res) => {
            let result = res.data.data;
            // result["平均值"].forEach((item,index)=>{
            //   result["平均值"][index]= Number(item)
            // })
            console.log(result["平均值"]);
            let name = "";
            this.dbData.forEach((item) => {
              if (item.code === this.searchObj.ITEM) {
                name = item.name;
              }
            });
            this.detailInfo = {};
            var chart1 = {
              categoryData: result.date,
              seriesData: [
                {
                  name: name,
                  value: result["平均值"],
                },
              ],
            };
            console.log(chart1);
            this.detailInfo.chart1 = chart1;
            let newarr = [];
            console.log(this.detailInfo.chart1.seriesData);
            this.detailInfo.chart1.seriesData.forEach((item) => {
              item.value.forEach((val) => {
                newarr.push(val);
              });
            });
            if (this.getNull(newarr) == newarr.length) {
              this.gridLeft = [0, 0, 0, 40];
            } else {
              this.gridLeft = [0, 0, 0, 0];
            }
            this.detailInfo.max = result["最大功率"];
            this.detailInfo.pj = result["平均功率"];
            this.initEchart();
          })
          .catch((wrong) => {
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
            currentValue === null ||
            JSON.parse(currentValue) === null ||
            typeof currentValue == "Null"
        )
          return total + 1;
        return total;
      }, 0);
    },
    initEchart() {
      var {categoryData, seriesData} = this.detailInfo.chart1;
      var echarts = this.$echarts;
      var series = [];
      seriesData.forEach((item, idx) => {
        var obj = {
          type: "line",
          name: item.name,
          data: item.value,
          animationDurationUpdate: 2000,
          animationEasingUpdate: 'quinticInOut',
          smooth: false,
          showSymbol: false,
          areaStyle: {
            //区域填充样式
            normal: {
              //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
              color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(51,102,255,0.20)",
                    },
                    {
                      offset: 1,
                      color: "rgba(51,102,255,0.00)",
                    },
                  ],
                  false
              ),
              shadowColor: "rgba(53,142,215, 0.9)", //阴影颜色
              shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
            },
          },
        };
        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "2%",
          right: "4%",
          bottom: "7%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          right: 0,
          top: "0%",
          orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        xAxis: {
          type: "category",
          data: categoryData,
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: {
          name: this.LangHandler('powerkw','功率(kW)'),
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
            padding: this.gridLeft,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 10,
          // max: 100
        },

        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },

  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      if (this.dbData.length > 0) {
        this.searchObj.ITEM = this.dbData[0].code;
        console.log(this.searchObj.ITEM);
      }
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.carbonBox4 {
  .card-content1 {
    padding-left: 0px;
    height: 68px;
    width: 70%;

    .display-item {
      width: 185px;
      height: inherit;
      box-sizing: border-box;
      display: inline-flex;
      flex-direction: column;

      div {
        &:nth-child(1) {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }

        &:nth-child(2) {
          font-size: 32px;
          font-weight: 700;
          color: #3366ff;
          line-height: 40px;

          font {
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 20px;
            margin-left: 6px;
          }
        }
      }
    }
  }

  .card-content2 {
    flex: 1;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
